var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"body"},[_vm._m(0),_c('section',{staticClass:"layout"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('div',{staticClass:"news"},[_c('h3',[_vm._v("媒体报道")]),(_vm.loading)?_c('div',{staticClass:"loading"},[_c('span',[_vm._v("加载中…")])]):_c('ul',{staticClass:"list"},_vm._l((_vm.list),function(item,index){return _c('li',{key:index,staticClass:"item"},[_c('a',{attrs:{"href":item.metadata[0],"target":"_blank"}},[_c('div',{staticClass:"thumbnail",style:(`background-image: url(${item.thumbnail})`)}),_c('p',[_vm._v(_vm._s(item.title.rendered))])])])}),0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"banner"},[_c('div',{staticClass:"wrap"},[_c('h2',[_vm._v("流动核酸采样SVaaS解决方案")]),_c('p',[_vm._v("通过人工智能，大数据和车辆追踪技术，"),_c('br',{staticClass:"mShow"}),_vm._v("实现场景数字化"),_c('br'),_vm._v("对接政府数字化平台，"),_c('br',{staticClass:"mShow"}),_vm._v("形成服务城市的大数据平台")]),_c('div',{staticClass:"preview"},[_c('img',{staticClass:"pc",attrs:{"src":require("../../assets/v3/svaas/preview_pc.png"),"width":"788","height":"468"}}),_c('img',{staticClass:"app",attrs:{"src":require("../../assets/v3/svaas/preview_app.png"),"width":"312","height":"644"}})]),_c('div',{staticClass:"enter"},[_c('span',{staticClass:"t"},[_vm._v("智慧移动防疫车管理系统")]),_c('span',{staticClass:"tag"},[_vm._v("平 台")]),_c('span',{staticClass:"tag"},[_vm._v("app端")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"intro"},[_c('img',{staticClass:"img",attrs:{"src":require("../../assets/v3/svaas/kt_map.png"),"width":"370","height":"296"}}),_c('div',{staticClass:"con"},[_c('h3',[_vm._v("从“被动采样”走向“主动健康”")]),_c('ol',[_c('li',[_vm._v("SVaaS聚焦于移动健康服务车+云调度管理平台，通过软硬件相结合的方式为政府提供流动式、可灵活调度的核酸采样圈管理服务方案；")]),_c('li',[_vm._v("SVaaS提供的不是传统的软件与硬件采购，而是一种“新型新冠防疫服务模式”；")])]),_c('h4',[_vm._v("可计划、可定位、可调度、可监控、可调整的智能化机动化防疫“部队”")]),_c('ol',[_c('li',[_vm._v("通过移动健康服务车的智能化，实现“流动采样+信息传输节点+流动监控”")]),_c('li',[_vm._v("单点智能+5G联网，与『指挥大厅』应急调度管理系统 “数据+信息”通联，实现指挥调度、实时监控、采样统计、数据反馈等")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"moduleA"},[_c('div',{staticClass:"item"},[_c('img',{staticClass:"img",attrs:{"src":require("../../assets/v3/svaas/kt_car.png"),"width":"174","height":"90"}}),_c('div',{staticClass:"con"},[_c('h3',[_vm._v("流动式智能健康服务车")]),_c('p',[_vm._v("15分钟内到达规定和需求地点，快速采样送样，快速撤离、消杀，杜绝进一步传染")])])]),_c('div',{staticClass:"item"},[_c('img',{staticClass:"img",attrs:{"src":require("../../assets/v3/svaas/hall.png"),"width":"206","height":"154"}}),_c('div',{staticClass:"con"},[_c('h3',[_vm._v("『指挥大厅』")]),_c('p',[_vm._v("实时掌握采样点数据、趋势，"),_c('br'),_vm._v("现场动态和突发，在线调度和下达任务")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dispatch"},[_c('h3',[_vm._v("指挥调度系统")]),_c('div',{staticClass:"item"},[_c('h4',[_vm._v("现场实时")]),_c('p',[_vm._v("监测现场、采样人群")]),_c('p',[_vm._v("单点覆盖范围和行动轨迹")]),_c('p',[_vm._v("监测和视频采集现场突发状况")]),_c('p',[_vm._v("AI摄像头视频采集+智能处理，人流过密预警")])]),_c('div',{staticClass:"item"},[_c('h4',[_vm._v("决策分析")]),_c('p',[_vm._v("各种数据汇总、统计；科学分析和决策；准确预测、监管和引导")])]),_c('div',{staticClass:"item"},[_c('h4',[_vm._v("应急指挥")]),_c('p',[_vm._v("现场可视、资源可视，多部门协同共享实况直播透明化；展现方式多样化")])]),_c('div',{staticClass:"item"},[_c('h4',[_vm._v("整体态势")]),_c('p',[_vm._v("实时掌握采样人群数量； 防疫车辆等实际位置，了解各个单元的动态轨迹")])]),_c('div',{staticClass:"item"},[_c('h4',[_vm._v("精准指挥")]),_c('p',[_vm._v("对整个态势做到实时掌握后，高效通信+机动灵活部队成为精准指挥的重要保障")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flow"},[_c('ul',{staticClass:"brag"},[_c('li',[_vm._v("车辆远程管理")]),_c('li',[_vm._v("动态路径优化")]),_c('li',[_vm._v("数据开放可接入")]),_c('li',[_vm._v("可计划")]),_c('li',[_vm._v("可调度")]),_c('li',[_vm._v("可视化")])]),_c('img',{staticClass:"img",attrs:{"src":require("../../assets/v3/svaas/flow.png"),"width":"1160","height":"778"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"moduleB"},[_c('div',{staticClass:"item"},[_c('img',{staticClass:"img",attrs:{"src":require("../../assets/v3/svaas/carend.png"),"width":"520","height":"310"}}),_c('p',[_vm._v("车 载 端")])]),_c('div',{staticClass:"item"},[_c('img',{staticClass:"img",attrs:{"src":require("../../assets/v3/svaas/tbox.png"),"width":"584","height":"310"}}),_c('p',[_vm._v("T-Box")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"advantage"},[_c('h3',[_c('strong',[_vm._v("移动健康服务车")]),_vm._v("应用优势")]),_c('div',{staticClass:"item"},[_c('h4',[_vm._v("流动采样、灵活布点")]),_c('p',[_vm._v("车动人不动，核酸采样直接到达被采人员所在地（可移动、低成本）")])]),_c('div',{staticClass:"item"},[_c('h4',[_vm._v("多景应用")]),_c('p',[_vm._v("充分满足社区、村庄、工业园区、学校、医院等多点流动采样需求")])]),_c('div',{staticClass:"item"},[_c('h4',[_vm._v("机动高效单车单点采样＞2000人")]),_c('p',[_vm._v("覆盖范围大于固定、便民点，综合性价比远高于固定、便民采样点")])]),_c('div',{staticClass:"item"},[_c('h4',[_vm._v("采送一体")]),_c('p',[_vm._v("样品随车移动，无需多次转运，安全高效，样品恒温保存，避免环境温度影响检测结果 ")])]),_c('div',{staticClass:"item"},[_c('h4',[_vm._v("医护防护")]),_c('p',[_vm._v("环境舒适，隔窗采样，核酸结束，紫外臭氧消杀，极大降低传播风险，大幅降低二次感染几率，充分保护医护安全")])]),_c('div',{staticClass:"item"},[_c('h4',[_vm._v("安全核采杜绝核酸采集交叉感染")]),_c('p',[_vm._v("从人找点，到点找人，避免人群聚集核酸采样")])])])
}]

export { render, staticRenderFns }