<template>
  <section class="body">
    <section class="banner">
      <div class="wrap">
        <h2>流动核酸采样SVaaS解决方案</h2>
        <p>通过人工智能，大数据和车辆追踪技术，<br class="mShow" />实现场景数字化<br />对接政府数字化平台，<br class="mShow" />形成服务城市的大数据平台</p>
        <div class="preview">
          <img src="../../assets/v3/svaas/preview_pc.png" width="788" height="468" class="pc" >
          <img src="../../assets/v3/svaas/preview_app.png" width="312" height="644" class="app" >
        </div>
        <div class="enter">
          <span class="t">智慧移动防疫车管理系统</span>
          <span class="tag">平 台</span>
          <span class="tag">app端</span>
        </div>
      </div>
    </section>

    <section class="layout">
      <div class="intro">
        <img class="img" src="../../assets/v3/svaas/kt_map.png" width="370" height="296" />
        <div class="con">
          <h3>从“被动采样”走向“主动健康”</h3>
          <ol>
            <li>SVaaS聚焦于移动健康服务车+云调度管理平台，通过软硬件相结合的方式为政府提供流动式、可灵活调度的核酸采样圈管理服务方案；</li>
            <li>SVaaS提供的不是传统的软件与硬件采购，而是一种“新型新冠防疫服务模式”；</li>
          </ol>
          <h4>可计划、可定位、可调度、可监控、可调整的智能化机动化防疫“部队”</h4>
          <ol>
            <li>通过移动健康服务车的智能化，实现“流动采样+信息传输节点+流动监控”</li>
            <li>单点智能+5G联网，与『指挥大厅』应急调度管理系统 “数据+信息”通联，实现指挥调度、实时监控、采样统计、数据反馈等</li>
          </ol>
        </div>
      </div>

      <div class="moduleA">
        <div class="item">
          <img class="img" src="../../assets/v3/svaas/kt_car.png" width="174" height="90" />
          <div class="con">
            <h3>流动式智能健康服务车</h3>
            <p>15分钟内到达规定和需求地点，快速采样送样，快速撤离、消杀，杜绝进一步传染</p>
          </div>
        </div>
        <div class="item">
          <img class="img" src="../../assets/v3/svaas/hall.png" width="206" height="154" />
          <div class="con">
            <h3>『指挥大厅』</h3>
            <p>实时掌握采样点数据、趋势，<br />现场动态和突发，在线调度和下达任务</p>
          </div>
        </div>
      </div>

      <div class="dispatch">
        <h3>指挥调度系统</h3>
        <div class="item">
          <h4>现场实时</h4>
          <p>监测现场、采样人群</p>
          <p>单点覆盖范围和行动轨迹</p>
          <p>监测和视频采集现场突发状况</p>
          <p>AI摄像头视频采集+智能处理，人流过密预警</p>
        </div>
        <div class="item">
          <h4>决策分析</h4>
          <p>各种数据汇总、统计；科学分析和决策；准确预测、监管和引导</p>
        </div>
        <div class="item">
          <h4>应急指挥</h4>
          <p>现场可视、资源可视，多部门协同共享实况直播透明化；展现方式多样化</p>
        </div>
        <div class="item">
          <h4>整体态势</h4>
          <p>实时掌握采样人群数量； 防疫车辆等实际位置，了解各个单元的动态轨迹</p>
        </div>
        <div class="item">
          <h4>精准指挥</h4>
          <p>对整个态势做到实时掌握后，高效通信+机动灵活部队成为精准指挥的重要保障</p>
        </div>
      </div>

      <div class="flow">
        <ul class="brag">
          <li>车辆远程管理</li>
          <li>动态路径优化</li>
          <li>数据开放可接入</li>
          <li>可计划</li>
          <li>可调度</li>
          <li>可视化</li>
        </ul>

        <img class="img" src="../../assets/v3/svaas/flow.png" width="1160" height="778" />
      </div>

      <div class="moduleB">
        <div class="item">
          <img class="img" src="../../assets/v3/svaas/carend.png" width="520" height="310" />
          <p>车 载 端</p>
        </div>
        <div class="item">
          <img class="img" src="../../assets/v3/svaas/tbox.png" width="584" height="310" />
          <p>T-Box</p>
        </div>
      </div>

      <div class="advantage">
        <h3><strong>移动健康服务车</strong>应用优势</h3>
        <div class="item">
          <h4>流动采样、灵活布点</h4>
          <p>车动人不动，核酸采样直接到达被采人员所在地（可移动、低成本）</p>
        </div>
        <div class="item">
          <h4>多景应用</h4>
          <p>充分满足社区、村庄、工业园区、学校、医院等多点流动采样需求</p>
        </div>
        <div class="item">
          <h4>机动高效单车单点采样＞2000人</h4>
          <p>覆盖范围大于固定、便民点，综合性价比远高于固定、便民采样点</p>
        </div>
        <div class="item">
          <h4>采送一体</h4>
          <p>样品随车移动，无需多次转运，安全高效，样品恒温保存，避免环境温度影响检测结果 </p>
        </div>
        <div class="item">
          <h4>医护防护</h4>
          <p>环境舒适，隔窗采样，核酸结束，紫外臭氧消杀，极大降低传播风险，大幅降低二次感染几率，充分保护医护安全</p>
        </div>
        <div class="item">
          <h4>安全核采杜绝核酸采集交叉感染</h4>
          <p>从人找点，到点找人，避免人群聚集核酸采样</p>
        </div>
      </div>

      <div class="news">
        <h3>媒体报道</h3>
        <div class="loading" v-if="loading">
          <span>加载中…</span>
        </div>
        <ul class="list" v-else>
          <li class="item" v-for="(item, index) in list" :key="index">
            <a :href="item.metadata[0]" target="_blank">
              <div class="thumbnail" :style="`background-image: url(${item.thumbnail})`" />
              <p>{{item.title.rendered}}</p>
            </a>
          </li>
        </ul>
      </div>
    </section>

  </section>
</template>

<script>
export default {
  name: "SVaaS",
  data() {
    return {
      loading: true,
      list: [],
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.axios({
        url: 'posts?categories=15&per_page=3&page=1'
      }).then((res) => {
        const { data } = res;
        
        this.list = data;
        this.loading = false;
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.banner {
  margin-bottom: 360px;
  color: #fff;
  font-size: 28px;
  line-height: 40px;
  background-color: #E83E0B;

  .wrap {
    position: relative;
    top: 260px;
    display: flex;
    margin: 0 auto;
    padding: 0 40px 24px;
    max-width: 1160px;
    flex-direction: column;
  }

  h2 {
    margin-bottom: 40px;
    color: #fff;
    font-size: 78px;
    line-height: 1.2;

    strong {
      font-weight: bold;
    }
  }

  .preview {
    position: relative;
    margin-top: 60px;

    .pc {
      display: block;
      max-width: 100%;
      height: auto;
    }

    .app {
      position: absolute;
      top: -132px;
      right: 0;
    }
  }

  .enter {
    display: flex;
    align-items: center;
    padding: 16px 340px 16px 24px;
    font-size: 24px;
    line-height: 36px;
    color: #000;

    .t {
      margin-right: auto;
    }

    .tag {
      margin-left: 20px;
      line-height: 34px;
      padding: 0 20px;
      min-width: 138px;
      font-size: 20px;
      color: #00A684;
      text-align: center;
      font-weight: bold;
      border: 1px solid #00A684;
      border-radius: 6px;
    }
  }
}

.layout {
  margin: 120px auto;
  padding: 0 20px;
  font-size: 18px;
  line-height: 24px;
  max-width: 1200px;
}

.intro {
  display: flex;
  align-items: flex-start;

  .img {
    width: 370px;
    height: auto;
  }

  .con {
    margin-left: 80px;
    flex: 1;

    h3 {
      margin-bottom: 16px;
      font-size: 42px;
      line-height: 50px;
      font-weight: bold;
    }

    h4 {
      margin-top: 48px;
      margin-bottom: 16px;
      font-size: 22px;
      line-height: 30px;
      font-weight: bold;
    }

    ol {
      margin-left: 20px;
      list-style: upper-roman;

      li {
        margin-bottom: 8px;
      }
    }
  }
}

.moduleA {
  display: flex;
  margin: 50px 0 80px;
  padding: 32px 24px;
  background-color: #EBF4EF;

  .item {
    display: flex;
    align-items: flex-start;
    padding-top: 20px;
    flex: 1;

    & + .item {
      margin-left: 80px;
      flex: 1.17;

      .img {
        margin-top: -20px;
      }
    }

    .con {
      margin-left: 40px;

      h3 {
        margin-bottom: 8px;
        font-size: 24px;
        line-height: 28px;
        font-weight: bold;
      }
    }
  }
}

.dispatch {
  column-count: 3;
  column-gap: 40px;
  line-height: 28px;

  h3 {
    margin-bottom: 38px;
    font-size: 42px;
    font-weight: bold;
    line-height: 46px;
  }

  .item {
    margin-bottom: 50px;
    padding-left: 20px;

    &:first-of-type {
      padding-left: 0;
    }

    h4 {
      margin-bottom: 8px;
      font-size: 24px;
      line-height: 28px;
      font-weight: bold;
    }
  }
}

.flow {
  padding: 20px 0;

  .brag {
    margin-bottom: 90px;
    display: flex;
    justify-content: flex-end;

    li {
      padding: 0 10px;
      font-size: 20px;
      line-height: 24px;
      font-weight: bold;

      &::before {
        margin-right: 6px;
        display: inline-block;
        vertical-align: top;
        width: 24px;
        height: 24px;
        content: '';
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath d='M10 0a10 10 0 1 0 10 10A10.031 10.031 0 0 0 10 0zm6.498 7.125l-8.373 8.373-4.627-4.623a1.238 1.238 0 1 1 1.753-1.75L8.125 12l6.624-6.624a1.237 1.237 0 0 1 1.75 1.75z' fill='%230f9e7f'/%3E%3C/svg%3E");
        background-size: contain;
      }
    }
  }

  .img {
    width: 100%;
    height: auto;
  }
}

.moduleB {
  display: flex;
  margin: 60px 0 90px;
  justify-content: space-between;

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;

    & + .item {
      margin-left: 56px;
    }

    .img {
      max-width: 100%;
      height: auto;
    }

    p {
      margin-top: 28px;
      padding: 0 20px;
      min-width: 138px;
      font-size: 24px;
      line-height: 38px;
      color: #00A684;
      text-align: center;
      font-weight: bold;
      border: 1px solid #00A684;
      border-radius: 8px;
    }
  }
}

.advantage {
  column-count: 3;
  column-gap: 20px;

  h3 {
    margin-bottom: 28px;
    column-span: all;
    font-size: 42px;
    line-height: 52px;
  }

  .item {
    position: relative;
    margin-bottom: 24px;

    &:nth-of-type(1), &:nth-of-type(2) {
      padding-right: 80px;
    }
    &:nth-of-type(3), &:nth-of-type(4) {
      left: -40px;
    }

    h4 {
      margin-bottom: 2px;
      font-size: 24px;
      line-height: 34px;
      font-weight: bold;
    }
  }
}

.news {
  padding: 60px 0;

  h3 {
    font-size: 42px;
    line-height: 52px;
  }

  .loading {
    margin: 100px 0 0;
    text-align: center;

    span {
      position: relative;
      display: inline-block;
      vertical-align: top;
      padding: 0 46px;
      font-size: 22px;
      line-height: 60px;
      border-radius: 3px;

      &:hover {
        background-color: #efefef;
      }

      &::after {
        position: absolute;
        top: -50%;
        left: -50%;
        content: '';
        width: 200%;
        height: 200%;
        border: 3px solid #000;
        border-radius: 6px;
        transform: scale(.5);
      }
    }
  }

  .list {
    display: flex;
    flex-wrap: wrap;

    li {
      margin: 36px 40px 0 0;
      flex: 1;
      min-width: 28%;

      &:nth-child(3n) {
        margin-right: 0;
      }
      
      .thumbnail {
        position: relative;
        width: 100%;
        height: 0;
        padding-top: 56.25%;
        background: no-repeat 50%;
        background-size: cover;
        overflow: hidden;
      }

      p {
        padding: 20px 24px;
        text-align: center;
        color: #000;
      }
    }
  }
}


@media only screen and (max-width: 500px) {
  .banner {
    margin-bottom: 160px;
    font-size: 14px;
    line-height: 20px;
    
    .wrap {
      top: 160px;
      padding: 0 24px 52px;
    }

    h2 {
      margin: 0 0 8px;
      font-size: 24px;
      line-height: 36px;
    }

    .preview {
      margin-top: 12px;
      padding-right: 88px;

      .app {
        top: -48px;
        width: 30%;
        height: auto;
      }
    }

    .enter {
      margin-top: 32px;
      padding: 0;
      font-size: 14px;
      line-height: 24px;
      color: #000;

      .t {
        margin-right: auto;
      }

      .tag {
        margin-left: 8px;
        line-height: 22px;
        padding: 0 8px;
        min-width: 68px;
        font-size: 12px;
      }
    }
  }

  .layout {
    margin: 0 auto;
    padding: 0 24px;
    font-size: 14px;
    line-height: 20px;
  }

  .intro {
    display: block;

    .img {
      display: block;
      margin: 0 auto 24px;
      width: 60%;
      height: auto;
    }

    .con {
      margin-left: 0;

      h3 {
        margin-bottom: 8px;
        font-size: 24px;
        line-height: 36px;
      }

      h4 {
        margin-top: 24px;
        margin-bottom: 8px;
        font-size: 16px;
        line-height: 24px;
        font-weight: bold;
      }

      ol {
        li {
          margin-bottom: 4px;
        }
      }
    }
  }

  .moduleA {
    display: block;
    margin: 24px -12px 36px;
    padding: 24px 12px;
    background-color: #EBF4EF;
    border-radius: 6px;

    .item {
      padding-top: 0;
      flex: 1;

      & + .item {
        margin-left: 0;
        margin-top: 24px;

        .img {
          margin-top: 0;
        }
      }

      .img {
        width: 120px;
        height: auto;
      }

      .con {
        margin-left: 24px;

        h3 {
          margin-bottom: 4px;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }

  .dispatch {
    column-count: 1;
    column-gap: 0;
    line-height: 20px;

    h3 {
      margin-bottom: 20px;
      font-size: 24px;
      font-weight: bold;
      line-height: 36px;
    }

    .item {
      margin-bottom: 24px;
      padding-left: 0;

      h4 {
        margin-bottom: 4px;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .flow {
    padding: 10px 0;

    .brag {
      margin-bottom: 36px;
      flex-wrap: wrap;
      justify-content: space-between;

      li {
        width: 32%;
        padding: 4px 0;
        font-size: 12px;
        line-height: 20px;
        font-weight: bold;

        &:nth-child(3n) {
          width: 35%;
        }

        &::before {
          margin-right: 4px;
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .moduleB {
    margin: 28px 0 40px;

    .item {
      flex: 1;

      & + .item {
        flex: 1.123;
        margin-left: 24px;
      }

      p {
        margin-top: 12px;
        padding: 0 8px;
        min-width: 68px;
        font-size: 12px;
        line-height: 22px;
        border-radius: 6px;
      }
    }
  }

  .advantage {
    column-count: 1;
    column-gap: 0;

    h3 {
      margin-bottom: 20px;
      font-size: 24px;
      line-height: 36px;
    }

    .item {
      margin-bottom: 24px;

      &:nth-of-type(1), &:nth-of-type(2) {
        padding-right: 0;
      }
      &:nth-of-type(3), &:nth-of-type(4) {
        left: 0;
      }

      h4 {
        margin-bottom: 4px;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .news {
    padding: 36px 0 0;

    h3 {
      margin-bottom: 36px;
      font-size: 24px;
      line-height: 36px;
    }

    .loading {
      margin: 0 0 50px;

      span {
        padding: 0 30px;
        font-size: 14px;
        line-height: 38px;

        &::after {
          border: 2px solid #000;
        }
      }
    }

    .list {
      display: block;

      li {
        margin: 0 0 24px;

        p {
          padding: 8px 12px;
        }
      }
    }
  }
}

</style>